<template>
  <module-list-layout :createAction="createAction" :module="module" :header="false" v-if="amReady" style="width: 1200px !important;" class="mt-3">
    <exit-button></exit-button>
    <h6>list Office Inventory <el-button @click="createAction" size="small" class="p-2" type="primary" icon="el-icon-plus">Create</el-button></h6>

    <el-dialog :visible.sync="dialogVisible" title="" top="3vh" width="1200px" custom-class="el-custom-dialog" >
      <moduleForm
          :autoBind="false"
          :formHeader="true"
          :edit-mode="editMode"
          :bindingForView="true"
          :dataToBind="dataToBind"
          :on-edit-callback="afterEdit"
          :on-create-callback="afterCreate"
          :moduleDeleteAction='moduleFormDeleteAction'
          :formTitle="((editMode)? 'Update ' : 'Create ') + module.info.caption"
          v-if="dialogVisible"></moduleForm>
    </el-dialog>

    <div class="row">
      <div class="col-12 col-md-6">
        <dg-text-field
            :setFocus="true"
            style="max-width: 400px"
            class="mt-1"
            :field_span="24"
            :label_span="0"
            field_mark=""
            :translateLabel="false"
            label=""
            :defaultValue="userSearch"
            placeholder="search here"
            @amUpdated="userSearchUpdated"
            :liveUpdate="true"></dg-text-field>
      </div>
      <div class="col-12 col-md-6 float-right text-right">
         <span style="display: inline-block;margin-right: 20px;">
               <small> records: {{ recordsCounter }} | {{ pageChunkSize }} record per page</small>
        </span>
        <pagination
            style="margin-right: 20px;"
            class="mt-1 d-inline-block"
            :currentPage="currentPage"
            paginateClass=""
            :page-count="Math.ceil(recordsCounter / pageChunkSize)"
            :page-range="4"
            :margin-pages="2"
            :navigateHandler="navigateToPage"></pagination>

            <el-button v-if="listConfig.selection_column.show" type="danger" size="mini" icon="el-icon-delete" @click="removeSelectedAction">Selected</el-button>
      </div>
    </div>

    <el-table :data="tableData" border cell-class-name="p-1" stripe style="width: 100%" @selection-change="handleTableSelectionChange">
    <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          label="Name"
          prop="name"
          width="250">
        <template slot-scope="scope">
          <smartInput :customParams="{data:scope.row}" :inputValue="scope.row.name"
                      :noCopy="true"
                      :saveAction="updateField" editContainerStyle="top: 0; position: relative;" inputWidth='170px'
                      name='name'></smartInput>
        </template>
      </el-table-column>
      <el-table-column
          label="Supplier"
          prop="supplier_name"
          width="200">
      </el-table-column>
      <el-table-column
          label="Price"
          prop="price"
          width="200">
        <template slot-scope="scope">
          {{ $services['NumUtil'].format(scope.row.price) }}
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          label="Actions"
          prop="">
        <template slot-scope="scope">
          <vDropdown :actionsHandler="actionsHandler" :items="usersActions" :userProfileId="$store.state['App-User-state'].profile.id"
                     :params="{data: scope.row, rowIndex: scope.$index + 1} "></vDropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="deleteSelectedDialog" center title="Warning" width="420px" :append-to-body="true">
      <div align="center" class="mt-2 mb-2">Are you sure you want to remove all records ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteSelectedDialog = false" size="small">Cancel</el-button>
        <el-button @click="removeSelectedApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="deleteDialogVisible" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">You want to remove this record ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteDialogVisible = false" size="small">Cancel</el-button>
        <el-button @click="removeActionApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>
  </module-list-layout>
</template>

<script>
import module from './../../index'
export default {
  mixins: [$mixins['listView']],
  components: {moduleForm: () => import('../../form/View'), 'exit-button': () => import('@/components/exit-button/View')},
  methods: {...$mapActions(module.info.fullName + '-api', {getEntityList: 'list', updateEntity: 'update', multipleDelete: "multipleDelete"}),},
  computed: {...$mapState(module.info.fullName + '-api', ['pageChunkSize']),},
  beforeMount() {
    /*let promises = []
    promises.push($store.dispatch('Product-Brand-api/getDropDownList').then())
    Promise.all(promises).then((values) => {
      this.loadPageFromUrl()
    })*/

    this.loadPageFromUrl()
  },
  data() {
    return {
      module: {...module},
      usersActions: [
        {
          label: ' View / Edit',
          icon: 'el-icon-edit-outline',
          action: 'editAction',
          notForProfiles: []
        },
        {
          label: 'Remove',
          icon: 'el-icon-delete',
          action: 'removeAction',
        },
      ]
    };
  }
}
</script>
